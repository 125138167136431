.k-login-dropdown {
  @media screen and (min-width: 640px) {
    min-width: 30rem;
  }
}

.dropdown-item:active, .dropdown-item.active {
  background-color: $kelvion-yellow;
  color: $kelvion-black

}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: $kelvion-yellow-2;
  color: $kelvion-black
}
