th[role=columnheader]:not(.no-sort) {
  cursor: pointer;
}

th[role=columnheader]:not(.no-sort):after {
  content: "";
  float: right;
  margin-top: 7px;
  border-width: 0 4px 4px;
  border-style: solid;
  border-color: $gray-600 transparent;
  visibility: hidden;
  opacity: 0;
  user-select: none;
}

th[aria-sort=ascending]:not(.no-sort):after {
  border-bottom: none;
  border-width: 4px 4px 0;
}

th[aria-sort]:not(.no-sort):after {
  visibility: visible;
  opacity: 0.4;
}

th[role=columnheader]:not(.no-sort):hover:after {
  visibility: visible;
  opacity: 1;
}
