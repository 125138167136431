/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 20px 0 0; /* Space to navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  overflow-y: auto;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;

  &.active, &.active:hover {
    background-color: $primary;
    color: $white;
  }

  &:hover {
    background-color: darken($light, 10%);
  }
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}
