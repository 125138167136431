.k-system-group {
  background: $kelvion-yellow;
  height: 15rem;
  position: relative;
  display: grid;
  color: $kelvion-black;
  margin-bottom: 1rem;
}

.k-products-row {
  margin-bottom: 0;
}

.k-systems-row {
  margin-top: 0;
}

.k-logo-on-light {
  width: 200px;
}

/* ================= */
/* Intro Grid Styles */
/* ================= */

.intro-grid {
  --intro-grid-switch: calc((var(--intro-grid-threshold, 64rem) - 100%) * 999);
  display: flex;
  flex-wrap: wrap;
  gap: var(--intro-grid-gap, 0.5rem);

  > * {
    flex-basis: var(--intro-grid-switch);
    flex-grow: 999;
  }

  > *:first-child {
    flex-basis: max(16rem, var(--intro-grid-switch));
    flex-grow: 1;
  }

  & + & {
    margin-top: 1rem;
  }
}

