// Styles for Compare-Table
.highlight {
  --background: #c7dfeb;
}

tr:nth-child(even) td.highlight {
  --background: #b7cfdb;
}

.vertical {
  white-space: nowrap;
}

.k-compare-table {
  overflow: auto;
  max-height: calc(100vh - 13.5rem);
  border: 1px solid Rgba(0 0 0 / 10%);

  table {
    font-size: 0.875rem;
    min-width: 100%;
    border-collapse: collapse;
    background: #fff;
  }

  /* Table-Head  + Table-Body - th */
  thead th {
    font-size: 0.875rem;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: inset 0 -1px 0 Rgba(0 0 0 / 10%),
    inset 1px 0 0 Rgba(0 0 0 / 10%);
    vertical-align: bottom;
  }

  thead th:first-child {
    left: 0;
    z-index: 3;
    box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%),
    inset 0 -1px 0 Rgba(0 0 0 / 10%);
  }

  tbody th {
    white-space: nowrap;
    position: sticky;
    left: 0;
    z-index: 2;
    box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%);
  }

  /* Table Row styling */
  tr:nth-child(even) {
    --background: #f5f5f5;
  }

  tbody tr:hover,
  tbody tr:focus-within {
    --background: #c7dfeb;
  }

  /* General Styles th + td */
  th,
  td {
    background: var(--background, #fff);
    padding: 0.75rem 0.5rem;
    text-align: left;
  }

  td {
    width: 200px;
  }

  th {
    width: 100px;
  }

  /* Adjust depending on position */
  td:not(:first-child) {
    box-shadow: inset 1px 0 0 Rgba(0 0 0 / 10%);
    text-align: right;
    white-space: nowrap;
  }

  td:first-child {
    position: sticky;
    left: 0;
    padding: 0;
    box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%);
    min-width: 12ch;
    white-space: normal;
    word-break: break-all;

    @include media-breakpoint-up(sm) {
      min-width: 24ch;
    }
  }

  td:first-child a {
    padding: 0.75rem 1rem 0.75rem 0.5rem;
    font-weight: 600;
    text-decoration: none;
  }

  /* Table-Link Styles */
  a {
    color: inherit;
    display: block;
  }

  a:hover,
  a:focus {
    text-decoration: none;
    color: inherit;
    outline: 1px dashed #000;
    outline-offset: -0.25em;
    background-color: #ffec00;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 5l-7 5V0l7 5z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 0.4375rem 0.625rem;
    background-position: right 0.75em center;
  }

  img {
    height: 50px;
    width: 200px;
    object-fit: cover;
    object-position: 0 0;
    overflow: hidden;
  }

  .td-divider {
    padding: 0;
    height: 2px;
    background: #666;
  }

  .subheading {
    background: $kelvion-light-gray;
  }
}

#compareForm {
  display: inline;
}

#capcalcForm {
  display: inline;
}
