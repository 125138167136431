@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/proxima-nova-regular.woff2") format("woff2");
}
@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/proxima-nova-bold.woff2") format("woff2");
}
@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/proxima-nova-black.woff2") format("woff2");
}
