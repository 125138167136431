@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 1 through $grid-columns {

      @media print {
        .col-print#{$infix}-#{$i} {
          @include make-col($i, $grid-columns);
        }
      }
    }
  }
}

.k-print-header {
  position: relative;
}
.k-print-watermark {
  position: absolute;
  top: 0;
  right: 0;
  height: 120px;
  padding-top: 20px;

  &:after {
    content: "";
    background: url("/images/logos/kelvion-logo-on-light.svg") no-repeat center center;
    background-size: contain;
    opacity: 0.05;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}

.k-print-watermark-datasheet {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.k-print-watermark-app {
  text-align: center;
  letter-spacing: 2px;
}

@page {
  size: A4;
}

@media print {

  body {
    background: $white !important;
  }

  .card {
    break-inside: avoid;
  }

  .card-header {
    background: $kelvion-white !important;
  }
}
