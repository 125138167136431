.k-working-side-radio input[type="radio"] {
  display: none;

  + label {
    overflow: hidden;
    height: auto;
    user-select: none;
    transition: border 0.2s ease-in-out;

    img {
      width: auto;
      display: block;
      transition: all 0.2s ease-in-out;
      opacity: 0.5;
    }
  }

  &:hover + label {
    @extend .shadow-sm;

    img {
      opacity: 1;
    }
  }

  &:checked + label {
    border: 2px solid $primary;
    @extend .shadow-sm;

    img {
      opacity: 1;
    }
  }
}
