.k-option-category {
  top: 45px;
  z-index: 1;
  text-transform: uppercase;
  background: $kelvion-light-gray !important;
}

.custom-option-record {
  button {
    padding: 0;
    line-height: 1;
  }
}

#admin-analytics-custom-options {
  table {

    thead tr th:first-child, tbody th {
      min-width: 6ch;
      max-width: 12ch;
      @include media-breakpoint-up(sm) {
        min-width: 6ch;
        max-width: 12ch;
      }
    }

    thead th, tbody th, tbody td {
      font-size: 0.85rem;
    }

    tbody {
      td {
        border-left-width: 1px;
      }
    }
  }
}

#customOptionModal, #customOptionEditModal {

  .custom-option-error {
    margin-top: -0.75rem;
  }
}

#customOptionDeleteModal {
  table {

    th, td {
      text-align: left;
    }

    tr td:first-child {
      width: 180px;

    }
  }
}
