.k-bg-dark-grey {
  background-color: $kelvion-dark-gray;
}

.k-bg-grey {
  background-color: $kelvion-gray;
}

.k-bg-light-grey {
  background-color: $kelvion-light-gray;
}

.k-text-white {
  color: $kelvion-white;
}

.k-image-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
}
