*, *:before, *:after {
  box-sizing: border-box;
}

.k-steps {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  margin: 0;

  &__step {
    &:not(:first-child):not(:last-child) {
      flex-grow: 2;
    }

    flex-basis: 0;
    flex-grow: 1;
    position: relative;

    &:not(:first-child):before,
    &:not(:last-child):after {
      content: "";
      height: 2px;
      width: calc(50% - 1rem);
      position: absolute;
      top: 1.375rem;
      background: #DDE1E3;
    }

    &:first-child:after {
      width: calc(100% - 2rem);
    }

    &:last-child:before {
      width: calc(100% - 3.7rem);
    }

    &:last-child {
      .k-steps__item {
        margin-right: unset !important;;
      }
    }

    &:after {
      right: 0;
    }

    &[data-state="active"]:before,
    &[data-state="done"]:before,
    &[data-state="done"]:after {
      background: #333;
    }
  }

  &__item {
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.25rem;
    color: #90989C;
    text-decoration: none;

    &:before {
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      background: #90989C;
      border-radius: 50%;
      margin-bottom: 0.375rem;
      transition: background-color 0.2s ease,
      box-shadow 0.2s ease-out;
    }

    [data-state="active"] & {
      color: #333;
      font-weight: bold;

      &:before {
        background-color: var(--k-brand);
        border: 2px solid #333;
      }
    }

    [data-state="done"] & {
      color: #4B5256;

      &:before {
        border: 2px solid #333;
      }

      &:hover {
        color: #000;
      }

      &:hover:before {
        background-color: var(--k-brand);
        box-shadow: 0 0 0 6px rgba(var(--k-brand-rgb), 0.3);
      }

    }
  }

  &__home-item {
    width: 1.75rem;
    height: 1.75rem;
    background: var(--k-brand-text);
    margin-top: 0.5rem;
    margin-right: auto;
    display: flex;
    color: var(--k-brand);
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    svg {
      display: block;
      width: 60%;
      height: 60%;
    }

    &:hover {
      color: #000;
      background: var(--k-brand);
      box-shadow: 0 0 0 2px #000;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #fbfafa;
}

.step-container {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
  box-shadow: 0 2px 3px rgba(35, 51, 64, .15) !important;
}

.container-xxl {
  max-width: 1500px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-left: auto;
  margin-right: auto;
}

.d-none {
  display: none;
}

@media screen and (min-width: 576px) {
  .d-sm-block {
    display: block;
  }
}
