// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$brand-colors: (
        "brand": #ffec00,
        "brand-text": #333333,
        "news-bg": #ffec00,
        "news-headline-color": #333333,
        "page-headline-color": #333333,
        "text-highlight": #0b6fb0,
        "btn-primary-bg": #ffec00,
        "btn-primary-hover-bg": #e6d400,
        "btn-primary-active-bg": #e6d400,
        "btn-primary-color": #000
);
$brand-options: (
        "navbar-bg": #000000,
        "headline-transform": uppercase,
        "font-family-sans-serif": '"Proxima Nova", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
);

$brand-colors-rgb: map-loop($brand-colors, to-rgb, "$value");

:root {
  @each $color, $value in $brand-colors {
    --k-#{$color}: #{$value};
  }

  @each $color, $value in $brand-options {
    --k-#{$color}: #{$value};
  }

  @each $color, $value in $brand-colors-rgb {
    --k-#{$color}-rgb: #{$value};
  }
}

// Color system
$primary: $kelvion-blue-3;
$dark: $kelvion-blue-4;
$yiq-contrasted-threshold: 175;

// Body
//$body-bg: #ff0001;

// Components
$border-radius: 0;

// Typography
$font-family-sans-serif: var(--k-font-family-sans-serif);

// Tables
$table-head-bg: #f5f5f5;
$table-head-color: rgba(#000, 0.54);
$table-hover-bg: #b7cfdb;
$table-striped-bg: #fff;
$table-border-color: #e0e0e0;
$table-group-separator-color: #e0e0e0;

// Buttons
$btn-padding-x: 1.25rem;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;

// Forms
$input-group-addon-bg: #f6f6f6;
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;
$form-select-border-radius: 0;

$form-check-input-checked-bg-color: $kelvion-yellow;
$form-check-input-checked-color: $kelvion-black;
$form-check-input-indeterminate-bg-color: $kelvion-yellow;
$form-check-input-indeterminate-color: $kelvion-black;

// Navbar
$navbar-dark-brand-color: $kelvion-yellow;
$navbar-dark-brand-hover-color: $kelvion-yellow-1;

// Cards
$card-border-color: #efefef;
$card-cap-bg: #fff;

// Z-index
$zindex-dropdown: 1060;
