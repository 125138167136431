@import "~awesomplete/awesomplete.base";

.awesomplete > ul {
  // margin: .2em 0 0;

  background: $dropdown-bg;
  border: $dropdown-border-width solid $dropdown-border-color;
  text-shadow: none;
  width: 350px;
  z-index: 999;
}

.awesomplete > ul > li {
  //position: relative;
  padding: 0.5rem 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: $gray-300;
    color: $black;
  }

  &[aria-selected="true"] {
    background-color: $primary;
    color: $white;
  }
}

.awesomplete mark {
  padding: 0;
  background: $light;
  color: inherit; /* stylelint-disable-line sh-waqar/declaration-use-variable */
}

.awesomplete li:hover mark {
  background: inherit;
  color: inherit; /* stylelint-disable-line sh-waqar/declaration-use-variable */
}

.awesomplete li[aria-selected="true"] mark {
  background: darken($primary, 5%);
  color: inherit; /* stylelint-disable-line sh-waqar/declaration-use-variable */
}
