/* Popover Styles
   These are additional styles for bootstrap popovers.
   The style is added to the popover directly to customize e.g. the size.

   To use the popover you can set `data-bs-custom-class`. Example:

   <span data-controller="popover" data-bs-custom-class="k-popover-options" ...></span>
*/

.k-popover-options {
  width: 50rem;
  max-width: 50rem;
}

.k-popover-phe-thermal {
  width: 70rem;
  max-width: 70rem;
}

.k-popover-options-half {
  width: 25rem;
  max-width: 25rem;
}

.k-popover-product-list {
  font-size: 0.75rem;
  width: 54rem;
  max-width: 90vw;
  border: 2px solid #000;

  dt, dd {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-bottom: 1px solid Rgba(0 0 0 / 8%);
  }

  dd {
    margin-bottom: 0;
  }

  img {
    max-width: 25rem;
  }
}

.k-popover-ambient-profile {
  width: 20rem;

  .popover-body {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.k-popover-portfolio-details {
  width: 33rem;
  max-width: 33rem;
}
