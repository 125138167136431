.k-parameter-label {
  line-height: 1.5;
  font-size: $font-size-sm;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  letter-spacing: -0.02rem;
}
.fontsize-sm {
  font-size: $font-size-sm;
}

.k-parameter-select {
  border: none;
  padding-left: 0;
  padding-right: 1.25rem;
  background-position-x: right;
  -webkit-appearance: none;
  letter-spacing: -0.02rem;

  &:hover {
    outline: 1px solid $input-border-color;
  }
}

.k-parameter-input-group {
  min-width: $spacer * 2.5;
  letter-spacing: -0.02rem;
}

.k-custom-radio-inverted {
  .custom-control-input ~ .custom-control-label {
    &::after {
      background-image: escape-svg($form-check-input-checked-bg-image);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: none;
    }
  }
}

.custom-control-input ~ .custom-control-label {
  display: unset;
}

// Inverted radio buttons
.k-custom-radio-inverted2 .custom-control-input ~ .custom-control-label::after {
  background: #0b6fb0;
}

.k-custom-radio-inverted2 .custom-control-input:checked ~ .custom-control-label::after {
  border: 1px solid #999;
}

.k-custom-radio-inverted2 .custom-control-input:checked ~ .custom-control-label::after {
  background: #fff;
}

.k-form-check-lh-2 {
  line-height: 1.2;
}

.k-form-check-lh-0 {
  line-height: 1.0;
}

.k-form-set-quantities {

}

.awesomplete > ul {
  max-height: 310px;
  overflow-y: auto;
  //position: relative;
  display: inline-block;
}

.awesomplete {
  display: block;
}

.hiddenDiv {
  display: none;
}

.directSearchRanges {

}

.weather-data-import {
  .spinner-border {
    margin-right: 12px;
  }
}

.ht-lt-label {
  @extend .k-parameter-label;
  font-style: italic;
}

// Enable submit with enter. Fix for submit button when form contains multiple submit buttons.
.btn-default-submit {
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  opacity: 0;
  display: block;
}

.jdropdown {
    font-size: 0.875rem;
    font-family: var(--bs-body-font-family);
    width: 100%;
}

.jdropdown-default.jdropdown-focus .jdropdown-header {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #85b7d8;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(11, 111, 176, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(11, 111, 176, 0.25);
}

.jdropdown-default .jdropdown-selected,
.jdropdown-default .jdropdown-selected:hover,
.jdropdown-default .jdropdown-item:hover {
  background-image: none;
  background-color: #0b6fb0;
}