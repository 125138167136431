/* ==================== */
/* Start Teaser         */
/* ==================== */

.start-teaser {
  position: relative;

  header {
    height: 15rem;
    position: relative;
    display: grid;
    color: #fff;

    > * {
      grid-column: 1;
      grid-row: 1;
    }

    + * {
      margin-top: 1rem;
    }

    &:after, &:before {
      content: "";
      display: block;
      grid-column: 1;
      grid-row: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #000, rgba(0, 0, 0, 0));
      background-size: 100% 10rem;
      background-repeat: no-repeat;
    }

    &:before {
      background: #ffec00;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 15rem;
    object-fit: cover;
  }

  h2 {
    z-index: 2;
    font-size: 2.125rem;
    margin: 0.75rem 0.625rem;
    text-transform: uppercase;
    font-weight: 900;
    liine-height: 1.1;
  }

  ul {
    position: relative;
    z-index: 6;
    list-style: none;
    padding: 0;
    margin: 1rem 0 0;

    li + li {
      margin-top: 0.625rem;
    }

    a {
      text-decoration: none;
      line-height: 1.5;
      font-weight: 700;
      color: rgba(11, 111, 176, 0.5);
      display: block;
      padding: 0.375rem 1rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    }

    a[href] {
      color: rgba(11, 111, 176, 1);

      &:hover {
        background: #f0f0f0;
      }

      &:focus-visible {
        outline: 2px dashed currentcolor;
      }
    }


  }

  &__full-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;

    &:focus-visible {
      outline: 2px dashed #000;
      outline-offset: 5px;
    }

    &:hover ~ header,
    &:focus ~ header {
      &:before {
        opacity: 1;
      }

      h2 {
        color: #000;
      }
    }
  }

  &--nolink header img {
    // opacity: 0.5;
    // filter: grayscale(0.75);
  }

  &--nolink:hover,
  &--nolink:focus-visible {
    header:before {
      opacity: 1;
      z-index: 4;
      display: grid;
      place-items: center;
      color: #000;
      content: attr(data-nolink);
      font-size: 1.5rem;
    }
  }

  &--nolink:focus-visible {
    outline: 2px dashed #000;
    outline-offset: 5px;
  }

  &--group-headline {
    header:before,
    header:after {
      display: none;
    }

    header {
      background: #ffec00;

      h2 {
        color: #000;
      }
    }


  }
}


.start-teaser-header {
  height: 15rem;
  position: relative;
  display: grid;
  color: #fff;

  img {
    display: block;
    width: 100%;
    height: 15rem;
    object-fit: cover;
  }

  h2 {
    z-index: 2;
    font-size: 2.125rem;
    margin: 0.75rem 0.625rem;
    text-transform: uppercase;
    font-weight: 900;
    liine-height: 1.1;
  }

  > * {
    grid-column: 1;
    grid-row: 1;
  }

  + * {
    margin-top: 1rem;
  }

  &:after, &:before {
    content: "";
    display: block;
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000, rgba(0, 0, 0, 0));
    background-size: 100% 10rem;
    background-repeat: no-repeat;
  }

  &:before {
    background: #ffec00;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
}


/* ==================== */
/* Adaptive Grid Styles */
/* ==================== */

.adaptive-grid {
  --gap: 2rem;
  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
}

.adaptive-grid > * {
  flex-basis: 100%;
}

/* adjust the media-query according to your needs.*/
@media screen and (min-width: 64em) {
  .adaptive-grid > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  .adaptive-grid > *:nth-last-child(n+5),
  .adaptive-grid > *:nth-last-child(n+5) ~ * {
    flex-basis: calc((100% - (2 * var(--gap))) / 3);
    flex-grow: 0;
  }

}


/* ================================= */
/* Additional Styes for visual stuff */
/* ================================= */

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 2rem;
  background: #fbfafa;
}

h1 {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 3rem;
}

.container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.k-pwt-import {
  background: $kelvion-yellow;
  margin-left: 1rem !important;
}

.k-vertical-rule {
  border-left: 2px solid var(--k-btn-primary-color);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

span.badge {
  vertical-align: bottom;
}
