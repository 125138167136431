// Colors from the "Kelvion Corporate Design in Brief"

// primary
$kelvion-yellow: #ffec00;
$kelvion-white: #fff;
$kelvion-black: #000;

// secondary
$kelvion-dark-gray: #4b5256;
$kelvion-gray: #90989c;
$kelvion-light-gray: #dde1e3;

// yellow
$kelvion-yellow-1: #e7d300;
$kelvion-yellow-2: #bdad00;
$kelvion-yellow-3: #9c9000;
$kelvion-yellow-4: #615c00;

// red
$kelvion-red-1: #e5695b;
$kelvion-red-2: #d5312d;
$kelvion-red-3: #b71928;
$kelvion-red-4: #5e0c00;

// green
$kelvion-green-1: #94c356;
$kelvion-green-2: #7ae74a;
$kelvion-green-3: #329236;
$kelvion-green-4: #364e1f;

// blue
$kelvion-blue-1: #8bbad3;
$kelvion-blue-2: #569fc6;
$kelvion-blue-3: #0b6fb0;
$kelvion-blue-4: #003552;

// orange
$kelvion-orange-1: #f7ab59;
$kelvion-orange-2: #f3954a;
$kelvion-orange-3: #ec6608;
$kelvion-orange-4: #c54409;

// purple
$kelvion-purple-1: #a78bc0;
$kelvion-purple-2: #745b8a;
$kelvion-purple-3: #553876;
$kelvion-purple-4: #3b2451;
