// Style for all tables
.k-table-product-list, .k-table-responsive {
  overflow: auto;
  max-height: calc(100vh - 13.5rem);
  border: 1px solid Rgba(0 0 0 / 10%);

  table {
    font-size: 0.8rem;
    min-width: 100%;
    border-collapse: separate; /* Don't collapse - it fixes the sticky header issue. */
    border-spacing: 0;
    background: #fff;
  }

  /* Table-Head  + Table-Body - th, Table-Foot */
  thead th {
    font-size: 0.85rem;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 4;
    box-shadow: inset 0 -1px 0 Rgba(0 0 0 / 10%),
    inset 1px 0 0 Rgba(0 0 0 / 10%);
    vertical-align: top;
  }

  tfoot th, tfoot td {
    position: sticky;
    bottom: 0;
    z-index: 4;
    box-shadow: inset 0 1px 0 Rgba(0 0 0 / 10%),
    inset 1px 0 0 Rgba(0 0 0 / 10%);
    vertical-align: middle;
    border-top-width: 1px;
  }

  thead tr:nth-of-type(2) th {
    top: 29px;
  }

  tbody tr:nth-child(even) {
    --background: #f5f5f5;
  }

  tbody tr:hover {
    --background: #c7dfeb;
  }

  /* General Styles th + td */
  th,
  td {
    background: var(--background, #fff);
    padding: 0.75rem 0.5rem;
    text-align: left;
  }


  tbody th:last-of-type {
    position: sticky;
    left: 0;
    box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%);
    min-width: 12ch;
    white-space: normal;
    word-break: break-all;

    @include media-breakpoint-up(sm) {
      min-width: 24ch;
    }
  }
}

// Style for product list
.k-table-product-list {

  table {
    font-size: 0.8rem;
  }

  thead th {
    font-size: 0.85rem;
    vertical-align: top;
  }

  thead tr {

    th.product-name {
      z-index: 5;
      box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%),
      inset 0 -1px 0 Rgba(0 0 0 / 10%);
      left: 32px;
    }

    th:first-child {
      z-index: 5;
      box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%),
      inset 0 -1px 0 Rgba(0 0 0 / 10%);
      left: 0;
      min-width: 32px;
    }

  }

  tbody th {
    white-space: nowrap;
    position: sticky;
    left: 0;
    z-index: 2;
    box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%);
  }

  td {
    box-shadow: inset 1px 0 0 Rgba(0 0 0 / 10%);
    text-align: right;
    white-space: nowrap;
  }

  /* Table Row styling */

  tbody th:first-child {
    min-width: 32px;
  }

  tbody th:nth-of-type(2) {
    left: 32px;
  }

  tbody th:last-of-type {
    padding: 0;
  }

  tbody th:last-of-type a {
    padding: 0.75rem 1rem 0.75rem 0.5rem;
    font-weight: 600;
    text-decoration: none;
  }

  /* Table-Link Styles */
  a {
    color: inherit;
    display: block;
  }

  a:hover,
  a:focus {
    text-decoration: none;
    color: var(--k-brand-text);
    outline: 1px dashed var(--k-brand-text);
    outline-offset: -0.25em;
    background-color: var(--k-brand);
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 5l-7 5V0l7 5z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 0.4375rem 0.625rem;
    background-position: right 0.75em center;
  }

  a.plain-product-list-link:hover,
  a.plain-product-list-link:focus {
    text-decoration: initial;
    color: initial;
    outline: initial;
    outline-offset: initial;
    background-color: initial;
    background-image: initial;
    background-repeat: initial;
    background-size: initial;
    background-position: initial;
  }

  @media (max-width: 773px) {
    max-height: unset !important;
  }

}

// Style for tables
.k-table-responsive {

  thead tr:nth-of-type(2) th {
    top: 45px;
  }

  thead tr {

    th:first-child {
      z-index: 5;
      box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%),
      inset 0 -1px 0 Rgba(0 0 0 / 10%);
      left: 0;
    }

  }

  tbody th, tfoot th {
    white-space: nowrap;
    position: sticky;
    left: 0;
    z-index: 2;
    box-shadow: inset -1px 0 0 Rgba(0 0 0 / 10%);
  }

  tfoot th {
    z-index: 5;
  }

}

.k-part-th-number {
  width: 0.25rem;
}

.k-part-th-description {
  //width: 2.0rem;
}

.k-part-th-item {
  width: 11rem;
}

.k-part-th-quantity {
  width: 1rem;
}

.k-part-th-price {
  width: 7rem;
}

.k-table-data {
  font-size: 0.85rem;

  td,
  th {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  thead th {
    text-transform: uppercase;
    vertical-align: top;
  }

  table {
    margin-bottom: 0;
  }

}

.k-table-mrac-data {
  font-size: 0.85rem;

  td,
  th {
    text-align: right;
    page-break-inside: avoid;

    &:first-child {
      padding-left: 0;
      text-align: left;
      width: 20%;
    }

    &:last-child {
      padding-right: 0;
      white-space: nowrap;
    }
  }

  thead th {
    text-transform: uppercase;
    vertical-align: top;
  }

  table {
    margin-bottom: 0;
    table-layout: fixed;
    width: 100%;
  }

}

.k-table-numeric-data {
  font-size: 0.85rem;

  td {
    text-align: right;
    white-space: nowrap;
  }

  thead th {
    text-transform: uppercase;
    vertical-align: top;

    &:not(:first-child) {
      text-align: right;
    }

  }

  tbody {
    th:first-child {
      width: 20%;
    }

    .k-coil-td-unit {
      text-align: right;
      width: 20%;
    }
  }

  td,
  th {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

}

th, td, img {
  @media print {
    page-break-inside: avoid !important;
  }
}
