form#kelvion-code-search-form {

  input[type=text] {
    border: $kelvion-light-gray;
    background: $kelvion-light-gray;
    font-size: $font-size-sm;
  }

  button {
    border: $kelvion-light-gray;
    background: $kelvion-light-gray;
    color: $kelvion-black;
  }
}

