$system-border-width: 3rem;

$systems: (
  "dx": $kelvion-blue-3,
  "pump": $kelvion-blue-2,
  "brine": $kelvion-blue-1,
  "cond": $kelvion-red-2,
  "dc": $kelvion-red-1,
  "gc": $kelvion-purple-2,
  "sp": $kelvion-orange-1,
  "hx": $kelvion-green-1,
  "coils": $kelvion-yellow-1
);

@each $system-name, $system-color in $systems {
  .btn-system-#{$system-name} {
    @extend .text-start;
    @extend .py-2;

    background: $white;

    border: 1px solid $card-border-color;
    border-left: $system-border-width solid $system-color;

    &:disabled {
      border-left-color: darken($light, 7.5%);
    }

    &:hover:not([disabled]) {
      color: $primary;
      @extend .shadow-sm;
      border-left-color: darken($system-color, 7.5%);
    }
  }
}
