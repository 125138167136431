.one-pager {
  .basic-data-item {
    padding: 0;
  }

  .fact {
    padding: 0;
  }

  .sub-headline {
    margin: 0;
    font-size: 1rem;
  }

  .adaptive-grid, .basic-data-wrapper, .fan-adaptive-grid {
    margin: 0 0 0.25rem;
  }

  .img-fluid {
    max-width: 100%;
  }

  td, th, .card-body, .fact-card__headline {
    padding: 0 1rem;
  }

  #nomenclature {
    margin-bottom: 0 !important;
    font-size: 2rem;
  }

  .alert {
    padding: 0.5rem 0.5rem;
    margin-bottom: 0;
  }

  .single-fact-card {
    margin: 0.5rem auto;
  }
}
