
.row-full {
  background-color: var(--k-news-bg);
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.news {
  padding-bottom: 30px;

  .card {
    $card-height: 100%;
  }

  .card-link {
    color: $black;
  }
}

.jump-link {
  background: var(--k-news-bg);
  text-transform: uppercase;
  position: sticky;
  bottom: 0;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--k-news-headline-color);
  margin-top: 6rem;
  margin-inline: auto;
  max-width: 12ch;
  z-index: 7;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.news-section {
  margin-top: -6rem;
  position: relative;
  z-index: 8;

  min-height: 500px;
  background: var(--k-news-bg);
}

.news-headline {
  color: var(--k-news-headline-color);
}
