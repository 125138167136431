#coilsProductKey {
  font-size: 1.8rem;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.1;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;

  > *:first-child {
    color: #6c757d;
  }
}

.k-total-price {
  padding: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}
