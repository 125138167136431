#nomenclature {
  font-size: 2.125rem;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.1;
  margin: 0;

  > *:first-child {
    color: #6c757d;
  }
}

/* ==================== */
/* Adaptive Grid Styles */
/* ==================== */

.adaptive-grid {
  --gap: 1.5rem;
  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
  margin: var(--gap) auto;
}

.adaptive-grid > * {
  flex-basis: 100%;
}

/* adjust the media-query according to your needs.*/
@media screen and (min-width: 50em),
print {
  .adaptive-grid > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  .adaptive-grid > *:nth-last-child(n+5),
  .adaptive-grid > *:nth-last-child(n+5) ~ * {
    flex-basis: calc((100% - (2 * var(--gap))) / 3);
    flex-grow: 0;
  }

}

.fan-adaptive-grid {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.sub-headline {
  margin-top: 3rem;
  margin-bottom: 0;
}

/*================= */
/* Fact-Card Styles */
/* ================ */

.fact-card {
  background: #fff;
  border: 1px solid #EFEFEF;
  @extend .avoid-page-break;


  &__container {
    padding: 0.5rem 1rem;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(280px, 100%), 1fr));
    gap: 0 2rem;
    font-size: 0.875rem;

    /* hack to remove underlines in last row */
    padding-bottom: 0;
    border-bottom: 0.5rem solid #fff;
    overflow: hidden;
  }

  &__headline {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 22px;
    background: #fff;
    padding: 0.5rem 1rem;
    margin: 0;
    border-bottom: 1px solid #EFEFEF;

    &__small {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 18px;
      background: #fff;
      padding: 0 0;
      margin: 0;
      border-bottom: 1px solid #EFEFEF;
    }
    @media print {
      font-size: 1rem;
      background: $kelvion-light-gray;
    }
  }

  &__sub-headline {
    padding: 0.5rem 1rem 0;
  }
}

.fact {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  color: #212529;
  padding: 0.5rem 0;

  @media print {
    padding: 0.25rem 0;
  }

  /* hack to remove underlines in last row */
  margin-bottom: -1px;
  margin-top: 1px;

  &:not(:last-child) {
    border-bottom: 1px solid #EFEFEF;
  }

  > span:first-child {
    font-weight: bold;
  }

  &__value {
    display: flex;
    text-align: right;
    gap: 1ch;
  }

  &__unit:not(:empty) {
    min-width: 3ch;
    display: inline-block;
  }
}

.coils-fact {
  > span:first-child {
    font-weight: normal;
  }
}

.card-header-headline {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 22px;
  background: #fff;
  margin: 0;
}

.single-fact-card {
  margin: 1.5rem auto;

  &:first-child {
    margin-top: 0;
  }
}

.avoid-page-break {
  break-inside: avoid !important;
}

.page-break {
  page-break-before: always !important;
}

.basic-data-wrapper {

  h2 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 22px;
    background: #fff;
    padding: 0.5rem 1rem;
    margin: 0;
    border: 1px solid #EFEFEF;
    border-bottom: none;

    @media print {
      font-size: 1rem;
      background: $kelvion-light-gray;
    }
  }
}

.basic-data-container {
  padding: 1rem 1rem;
  background: #fff;
  font-size: 0.875rem;
  border: 1px solid #EFEFEF;
  column-width: 17.5rem;
  column-gap: 2rem;
}

.basic-data-item {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  color: #212529;
  padding: 0.5rem 0;
  border-bottom: 1px solid #EFEFEF;
  break-inside: avoid;


  span:first-child {
    font-weight: bold;
  }
}

.sidebar-icon-button {
  display: flex;
  padding-left: 0.75rem;
}

.sidebar-icon-button > *:last-child {
  margin-left: auto;
  margin-right: auto;
}

.k-arial-kelvion-code {
  font-family: Arial, Helvetica, sans-serif;
}

.print-arial {
  @media print {
    font-family: Arial, Helvetica, sans-serif;
  }
}
