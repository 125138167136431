.mt-neg-3 {
  margin-top: -0.75rem !important;
}

.suggestions-record {
  cursor: pointer;
}

.selection-disabled {
  cursor: initial;
  pointer-events: none;
}

#climate-suggestions-data-card {
  .climate-categories {
    button {
      color: #333;
      border-color: #b7b7b7;
      background-color: #dde1e3;

      &.active, &:hover {
        color: white;
        background-color: #565e64;
      }
    }

  }

  .card-closed {
    #data-table-container {
      height: 250px;
      overflow-y: auto;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0;

    thead th {
      border-bottom-width: 2px;
    }

    // rel humidity column
    th:nth-child(3) {
      width: 80px;
    }

    // cooling technology column
    th:nth-child(1) {
      width: 120px
    }

    // wet bulb temp column
    th:nth-child(5) {
      width: 100px;
    }

    // date column
    th:last-child {
      width: 120px;
    }

    th {
      position: sticky;
      top: 0;
      background: white;
    }
  }
}

.total-costs-charts {
  width: 100%;
}

@media print {
  .container-xxl {
    float: none !important;
  }

  .page-break {
    page-break-before: always !important;
  }
}

#tco-table {
  @media print {

    table {
      border-collapse: separate !important;
      border-spacing: 0 !important;
      border-color: $kelvion-dark-gray !important;
      border-width: 2px !important;

      th, td {
        box-shadow: none;
        border-bottom-width: 2px !important;
        background: transparent;
        border-color: $kelvion-dark-gray !important;
      }

      thead {
        tr:first-child th {
          background: transparent;
          border-top-width: 2px !important;
        }

        tr th:first-child {
          background: transparent;
          border-right-width: 2px !important;
        }

        tr:nth-child(-n + 2) {
          th:first-child {
            border-left-width: 2px !important;
          }

          th:last-child {
            border-right-width: 2px !important;
          }
        }

        th {
          position: initial;
          top: initial;
        }
      }

      tbody th, tfoot th {
        background: transparent;
        position: initial;
        left: initial
      }

      tbody {
        tr:nth-last-child(-n+2) th, tr:nth-last-of-type(-n+2) td {
          border-top-width: 1px !important;
        }

        tr th:first-child {
          background: transparent;
          border-left-width: 2px !important;
          border-right-width: 2px !important;
        }

        tr td:last-child {
          border-right-width: 2px !important;
        }
      }


    }

    height: 100%;
    overflow: unset;
  }
}

#temp-sections-container {

  @media print {

    table {
      border-collapse: separate !important;
      border-spacing: 0 !important;
      border-color: $kelvion-dark-gray !important;
      border-width: 2px !important;
    }

    th, td {
      box-shadow: none;
      border-bottom-width: 2px !important;
      background: transparent;
      border-color: $kelvion-dark-gray !important;
    }

    thead {

      tr.first th, tr.second th {
        position: initial;
        background: transparent;
      }

      tr.first th {
        top: initial;
        border-top-width: 2px !important;
      }

      tr.first th:first-child, tr.second th:first-child {
        border-left-width: 2px !important;
      }

      tr.first th:last-child {
        border-right-width: 2px !important;
      }

    }

    tbody {
      tr th:first-child {
        border-left-width: 2px !important;
      }

      tr td:last-child {
        border-right-width: 2px !important;
      }

      tr:last-child th, tr:last-child td {
        border-bottom-width: 2px !important;
      }
    }

    tfoot {
      th, td {
        position: initial;
        bottom: initial;
        z-index: unset;
        background: transparent;
      }
    }

    height: 100%;
    overflow: unset;
  }
}

.centered-spinner-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// annual simulation form
#ambProfiles {
  .ambient-profiles-popover {
    padding: 0;
    border: 0;
    background-color: #fff;

    &:focus {
      outline: none;
    }

  }

  #capacity-profiles-table {
    thead th {
      text-transform: uppercase;
    }
  }

  #ap-cap-parameter-table {
    .td-parameter-first {
      width: 7rem;
      padding-right: 0.5rem;
      vertical-align: top;
    }

    .td-parameter-second {
      width: 10rem;
    }
  }
}

.invalid-color {
  color: #dc3545;
}

.align-text-right {
  text-align: right;
}

#weatherValidationErrorModal {
  .validation-result-table {
    max-height: 200px;
    overflow-y: auto;
  }
}
