// Only used for temporary spare parts integration from old system
.iframe-container {
  position: absolute;
  top: 60px;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
