@import "../node_modules/slim-select/dist/slimselect.css";

.k-custom-select-with-search svg.ss-arrow {
  margin-right: 10px;
}

.k-custom-select-with-search:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #85b7d8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(11, 111, 176, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(11, 111, 176, 0.25);
}

.k-custom-select-with-search.ss-content {
  z-index: 5;
}

.k-custom-select-with-search.ss-main {
  padding: 0.2rem 0.2rem 0.2rem 0.2rem;
}

.k-custom-select-with-search.ss-main.ss-disabled {
  background-color: var(--bs-secondary-bg);
  pointer-events: none;
}

.k-custom-select-with-search.ss-main, .k-custom-select-with-search .ss-search input {
  border-radius: 0;
  font-family: var(--bs-body-font-family);
  font-size: 1rem;
}

.k-custom-select-with-search.ss-content .ss-search input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #85b7d8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(11, 111, 176, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(11, 111, 176, 0.25);
}

.k-custom-select-with-search .ss-list {
  .ss-option.ss-highlighted,
  .ss-option:not(.ss-disabled).ss-selected,
  .ss-option:hover {
    color: $kelvion-black;
    background-color: $kelvion-yellow;
  }

  div {
    font-family: var(--bs-body-font-family);
    font-size: 0.875rem;
  }
}

.k-custom-select-with-search.ss-main .ss-values {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-custom-select-with-search.ss-main .ss-max {
  background-color: $kelvion-yellow;
  color: $kelvion-black;
  border-radius: unset;
}

.k-custom-select-with-search.ss-main .ss-deselect {
  display: none;
}

.k-custom-select-with-search.ss-main .ss-value {
  background-color: $kelvion-yellow;
  border-radius: unset;

  .ss-value-text {
    color: $kelvion-black;
    font-size: 0.9rem;
  }

  .ss-value-delete {
    border-left: solid 1px $kelvion-black;
    min-height: 1.2em;

    svg path {
      stroke: $kelvion-black;
    }
  }
}
